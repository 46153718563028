import $ from 'jquery';
import 'what-input';
// require('jquery.animate-number');

// import AOS from 'aos';


// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';


$(document).foundation();

// Check browser, apply class
const userAgent = navigator.userAgent;

if (userAgent.indexOf("Chrome") > -1) {
  if (userAgent.indexOf("CriOS") > -1) {
    $('body').addClass('crios');
  } else {
    $('body').addClass('chrome');
  }
} else if (userAgent.indexOf("Firefox") > -1) {
  $('body').addClass('firefox');
} else if (userAgent.indexOf("Safari") > -1) {
  $('body').addClass('safari');
} else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
  $('body').addClass('opera');
} else if (userAgent.indexOf("Edge") > -1) {
  $('body').addClass('edge');
} else if (userAgent.indexOf("MSIE") > -1 || !!document.documentMode == true) {
  $('body').addClass('msie');
} else {
  $('body').addClass('');
}

/// INIT

// Set animate offset to 1/3rd device height.
var winHeight = window.innerHeight;
var aosOffset = parseInt(winHeight / 3);

$(document).ready(function() {

  // Init Swipers
  const gradSwiper = new Swiper('.grad-swiper', {
    on: {
      beforeInit: function () {
        $('.grad-swiper').randomize('.swiper-wrapper', '.swiper-slide');
      }
    },
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: false,
    },
    loop: true,
    loopedSlides: 0,
    navigation: {
      enabled: false,
    },
    speed: 4000,

  });

  // Init  Animate-on-Scroll
  if (typeof window !== "undefined") AOS.init({
    duration: 1200,
    offset: aosOffset,
    once: true,
  });

  // Adjust AOS custom offset
  $('.aos-adjusted-offset').attr('data-aos-offset', aosOffset);
  matchHeight('.grad-swiper:not(.aos-animate)','.hero');

  // $(document).on('aos:in', function(detail) {
  //   console.log('animated in', detail);
  // });
  // $(document).on('aos:out', function(detail) {
  //   console.log('animated out', detail);
  // });

  $('.count').each(function() {
      var $this = $(this);

      // Function to animate the number
      function numberCounter() {
        var text = $this.text();
        if (/\d/.test(text)) {
          var delay = $this.attr('data-aos-delay');
          var finalNumber = parseFloat(text.replace(/[^\d.-]/g, ''));
          var prefix = text.match(/^[^\d.-]+/) || '';
          var suffix = text.match(/[^\d.-]+$/) || '';
          $this.delay(delay).animateNumber({
            number: finalNumber,
            numberStep: function(now, tween) {
              var formattedNumber = now.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              $this.text(prefix + formattedNumber + suffix);
            }
          }, 2000);
        }
      }

      // Create an observer instance
      var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          if ($(mutation.target).hasClass('aos-animate')) {
            numberCounter();
          }
        });
      });

      // Configuration of the observer
      var config = { attributes: true, attributeFilter: ['class'] };

      // Pass in the target node, as well as the observer options
      observer.observe(this, config);
  });
});


/// RESIZE

// Refresh on window resize.
$(window).resize(function() {
  // Adjust AOS custom offset
  $('.aos-adjusted-offset').attr('data-aos-offset', aosOffset);
  matchHeight('.grad-swiper:not(.aos-animate)','.hero');
});


/// INTERACTION

// Close off-canvas responsive menu on item activation (for use on one-pager)
/*
$('#offCanvas .responsive-menu a').on('click', function (){
  $('#offCanvas').foundation('close');
});
*/

/// EXPERIMENTS

// Element (.hero) background-position to follow mouse position

if ('ontouchstart' in window) {
} else {
  $(document).mousemove(function(event) {
    var windowWidth = $(window).width(),
        windowHeight = $(window).height(),
        scrollTop = $(document).scrollTop();

    if (scrollTop < windowHeight){

      var mouseXpercentage = (event.pageX * -1) / windowWidth * 100 + 100,
          mouseYpercentage = (event.pageY * -1) / windowHeight * 100 + 100;

      var cx = windowWidth / 2,
          cy = windowHeight / 2,
          ex = event.pageX,
          ey = event.pageY;

      // $('.hero').attr('style','--deg: ' + angle(cx, cy, ex, ey) + 'deg');
      $('.jq-track-x-y').attr('style','--x: ' + mouseXpercentage + '%; --y: ' + mouseYpercentage + '%;');
    }
  });
}

function angle(cx, cy, ex, ey) {
  var dy = ey - cy;
  var dx = ex - cx;
  var theta = Math.atan2(dy, dx); // range (-PI, PI]
  theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  //if (theta < 0) theta = 360 + theta; // range [0, 360)
  return Math.round(theta - 90);
}

// Match gradient to hero height
function matchHeight(elem, matchElem) {
  var matchHeight = $(matchElem).height();
  $(elem).height(matchHeight);
}

$('.swiper-menu').each(function (i) {
  $(this).click($('.swiper-menu'), function(e){
    e.preventDefault();
    processSwiper.slideTo(i);
  });
});

// FUNCTIONS

/** @preserve jQuery animateNumber plugin v0.0.14
 * (c) 2013, Alexandr Borisov.
 * https://github.com/aishek/jquery-animateNumber
 */

// ['...'] notation using to avoid names minification by Google Closure Compiler
(function($) {
  var reverse = function(value) {
    return value.split('').reverse().join('');
  };

  var defaults = {
    numberStep: function(now, tween) {
      var floored_number = Math.floor(now),
          target = $(tween.elem);

      target.text(floored_number);
    }
  };

  var handle = function( tween ) {
    var elem = tween.elem;
    if ( elem.nodeType && elem.parentNode ) {
      var handler = elem._animateNumberSetter;
      if (!handler) {
        handler = defaults.numberStep;
      }
      handler(tween.now, tween);
    }
  };

  if (!$.Tween || !$.Tween.propHooks) {
    $.fx.step.number = handle;
  } else {
    $.Tween.propHooks.number = {
      set: handle
    };
  }

  var extract_number_parts = function(separated_number, group_length) {
    var numbers = separated_number.split('').reverse(),
        number_parts = [],
        current_number_part,
        current_index,
        q;

    for(var i = 0, l = Math.ceil(separated_number.length / group_length); i < l; i++) {
      current_number_part = '';
      for(q = 0; q < group_length; q++) {
        current_index = i * group_length + q;
        if (current_index === separated_number.length) {
          break;
        }

        current_number_part = current_number_part + numbers[current_index];
      }
      number_parts.push(current_number_part);
    }

    return number_parts;
  };

  var remove_precending_zeros = function(number_parts) {
    var last_index = number_parts.length - 1,
        last = reverse(number_parts[last_index]);

    number_parts[last_index] = reverse(parseInt(last, 10).toString());
    return number_parts;
  };

  $.animateNumber = {
    numberStepFactories: {
      /**
       * Creates numberStep handler, which appends string to floored animated number on each step.
       *
       * @example
       * // will animate to 100 with "1 %", "2 %", "3 %", ...
       * $('#someid').animateNumber({
       *   number: 100,
       *   numberStep: $.animateNumber.numberStepFactories.append(' %')
       * });
       *
       * @params {String} suffix string to append to animated number
       * @returns {Function} numberStep-compatible function for use in animateNumber's parameters
       */
      append: function(suffix) {
        return function(now, tween) {
          var floored_number = Math.floor(now),
              target = $(tween.elem);

          target.prop('number', now).text(floored_number + suffix);
        };
      },

      /**
       * Creates numberStep handler, which format floored numbers by separating them to groups.
       *
       * @example
       * // will animate with 1 ... 217,980 ... 95,217,980 ... 7,095,217,980
       * $('#world-population').animateNumber({
       *    number: 7095217980,
       *    numberStep: $.animateNumber.numberStepFactories.separator(',')
       * });
       * @example
       * // will animate with 1% ... 217,980% ... 95,217,980% ... 7,095,217,980%
       * $('#salesIncrease').animateNumber({
       *   number: 7095217980,
       *   numberStep: $.animateNumber.numberStepFactories.separator(',', 3, '%')
       * });
       *
       * @params {String} [separator=' '] string to separate number groups
       * @params {String} [group_length=3] number group length
       * @params {String} [suffix=''] suffix to append to number
       * @returns {Function} numberStep-compatible function for use in animateNumber's parameters
       */
      separator: function(separator, group_length, suffix) {
        separator = separator || ' ';
        group_length = group_length || 3;
        suffix = suffix || '';

        return function(now, tween) {
          var negative = now < 0,
              floored_number = Math.floor((negative ? -1 : 1) * now),
              separated_number = floored_number.toString(),
              target = $(tween.elem);

          if (separated_number.length > group_length) {
            var number_parts = extract_number_parts(separated_number, group_length);

            separated_number = remove_precending_zeros(number_parts).join(separator);
            separated_number = reverse(separated_number);
          }

          target.prop('number', now).text((negative ? '-' : '') + separated_number + suffix);
        };
      }
    }
  };

  $.fn.animateNumber = function() {
    var options = arguments[0],
        settings = $.extend({}, defaults, options),

        target = $(this),
        args = [settings];

    for(var i = 1, l = arguments.length; i < l; i++) {
      args.push(arguments[i]);
    }

    // needs of custom step function usage
    if (options.numberStep) {
      // assigns custom step functions
      var items = this.each(function(){
        this._animateNumberSetter = options.numberStep;
      });

      // cleanup of custom step functions after animation
      var generic_complete = settings.complete;
      settings.complete = function() {
        items.each(function(){
          delete this._animateNumberSetter;
        });

        if ( generic_complete ) {
          generic_complete.apply(this, arguments);
        }
      };
    }

    return target.animate.apply(target, args);
  };

}(jQuery));

// On Class Change
(function($) {
  $.fn.onClassChange = function(cb) {
    return $(this).each((_, el) => {
      new MutationObserver(mutations => {
        mutations.forEach(mutation => cb && cb(mutation.target, mutation.target.className));
      }).observe(el, {
        attributes: true,
        attributeFilter: ['class'] // only listen for class attribute changes
      });
    });
  }
}(jQuery));

// Shuffle array
(function($) {
  $.fn.shuffle = function(array) {
    let currentIndex = array.length,  randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }
}(jQuery));

// Randomise element children
(function($) {
  $.fn.randomize = function(tree, childElem) {
    return this.each(function() {
      var $this = $(this);
      if (tree) $this = $(this).find(tree);
      var unsortedElems = $this.children(childElem);
      var elems = unsortedElems.clone();

      elems.sort(function() {
        return (Math.round(Math.random()) - 0.5);
      });

      for (var i = 0; i < elems.length; i++)
        unsortedElems.eq(i).replaceWith(elems[i]);
    });
  };
}(jQuery));
